



































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class EmulatorToolBar extends Vue {

  @Prop(
    {
      default: false,
    },
  )
  public isPreviewOpen!: boolean;

  @Prop(
    {
      default: false,
    },
  )
  public isPreviewEnabled!: boolean;

  private isMini: boolean = true;
  private timeMiniSeconds: number = 4;

  @Emit('onToolbarPreviewHandler')
  public onPreviewClick() {
    return;
  }

  public toolbarMiniToggle(): void {
    this.isMini = !this.isMini;
    if (!this.isMini) {
      setTimeout(() => {
        this.isMini = true;
      }, this.timeMiniSeconds * 1000);
    }
  }
}
