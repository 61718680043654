















































import { Component, Vue } from 'vue-property-decorator';
import EmulatorsList from '@/components/editor/emulatorsList/EmulatorsList.vue';
import EmulatorToolBar from '@/components/editor/base/EmulatorToolBar.vue';
import { namespace } from 'vuex-class';
import CMCEmulator from '@/components/cmc/CMCEmulator.vue';
import { Emulator, EmulatorComponent } from '@/models/EmulatorModels';
import Loader from '@/components/shared/Loader.vue';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';
import TestModeSwitch from '@/components/cmc/controls/TestModeSwitch.vue';

const emulatorModule = namespace('EmulatorModule');

@Component({
  components: {
    Loader,
    CMCEmulator,
    EmulatorsList,
    EmulatorToolBar,
    ConfirmationModal,
    TestModeSwitch,
  },
})
export default class EmulatorEditorBase extends Vue {

  public get isPreviewOpen() {
    return this.isEmulatorPreviewOpen && this.hasLoadedEmulator;
  }

  public get hasLoadedEmulator() {
    return this.currentEmulator;
  }

  @emulatorModule.Getter
  public currentEmulator!: Emulator;

  @emulatorModule.Getter
  public currentEmulatorPane!: EmulatorComponent;

  @emulatorModule.Getter
  public isLoadingEmulators!: boolean;

  @emulatorModule.Getter
  public isEmulatorPreviewOpen!: boolean;

  @emulatorModule.Mutation
  public setPreviewOpen!: (isOpen: boolean) => void;

  @emulatorModule.Action
  public fetchEmulators!: () => Promise<void>;

  @emulatorModule.Action
  public chooseEmulator!: (emulator: Emulator | null) => void;

  @emulatorModule.Action
  public chooseCurrentEmulatorPane!: (pane: EmulatorComponent | null) => void;

  public onToolbarPreviewHandler(): void {
    this.setPreviewOpen(!this.isEmulatorPreviewOpen);
  }

  public onOverlayClick(): void {
    this.setPreviewOpen(false);
  }

  protected created(): void {
    this.fetchEmulators();
  }

  protected beforeDestroy(): void {
    this.chooseEmulator(null);
    this.chooseCurrentEmulatorPane(null);
  }
}
